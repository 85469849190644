import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import AddLeadPopup from './components/AddLeadPopup';
import axios from 'axios';
import TableLoader from './components/TableLoader';

const CallerFollowUps = () => {
    const [toggleLeadPopup, setToggleLeadPopup] = useState(0);
    const [userDetails, setUserDetails] = useState([]);
    const [filterStatus, setfilterStatus] = useState("");

    const [clientData, setClientData] = useState([]);
    const [tableLoader, setTableLoader] = useState(0);

    const [date, setDate] = useState("");

    const url = window.url;
    const qry = new FormData();

    const navigate = useNavigate();

    useEffect(() => {
        setTableLoader(1);
        if (localStorage.getItem("user")) {
            const userData = JSON.parse(localStorage.getItem("user"));
            setUserDetails(userData);
            // console.log(userData);
            if (userData.designation == "D" || userData.designation == "F") {

            }
            else {
                navigate("/");
            }
            qry.append("emp_id", userData.user_id);

        }
        fetchMyLeadsApi();
    }, []);

    const reload = () => {
        qry.append("emp_id", userDetails.user_id);
        fetchMyLeadsApi();
    }
    const fetchMyLeadsApi = () => {
        setTableLoader(1);
        axios.post(url + "fetch-caller-followup.php", qry).then((response) => {
            if (response.data.status == 1) {
                // response.data.data.sort(function(a,b){
                //     return a.next_follow_time - b.next_follow_time;
                // });
                setClientData(response.data.data);
                setDate(response.data.date);
            }
            // console.log(response.data);

            setTableLoader(0);
        }).catch(error => {
            console.log("error");
        });
    }




    const closeLeadPopup = () => {
        setToggleLeadPopup(0);
    }

    var i = 0;
    const index = () => {
        i = i + 1;
    }

    return (
        <>


            <Header />

            <div className='container-head'>
                <div>
                    <h2> Today </h2>
                    <div className='filter'>

                        {/* <select onChange={(e) => setfilterStatus(e.target.value)}>
                            <option value=""> Filter Status</option>
                            <option value="Follow Up"> Follow Up</option>
                            <option value="Visiting"> Visiting </option>
                            <option value="Visited"> Visited</option>
                        </select> */}

                        <input type='date' onChange={(e) => setfilterStatus(e.target.value)} />
                        <i className='fa fa-calendar' onClick={() => setfilterStatus("")}></i>
                        {/* <input type='date' /> */}
                    </div>
                </div>

                <div>

                </div>
            </div>

            <div className='crm-container'>
                {tableLoader == 1 ?

                    <TableLoader />
                    :
                    <>
                        <div className='crm-table visible-hide'>

                            <table>
                                <thead>
                                    <tr> <th> S No</th> <th> Follow Date/Time </th> <th> Name </th> <th> Phone </th> <th> Lead For </th> <th> Status </th> <th> Action </th></tr>
                                </thead>

                                {
                                    clientData.length != 0 ?
                                        <>
                                            {filterStatus != "" ?
                                                clientData.filter((item) => item.next_follow_date == filterStatus).map((data) => {
                                                    index();
                                                    return (
                                                        <tr><td> {i}</td> <td>{data.next_follow_date} / {data.next_follow_time}</td> <td>{data.name}</td> <td> {data.phone} </td> <td>{data.site_name}</td> <td className='crm-color-primary'> {data.process_status == 1 ? "New" : data.process_status == 2 ? "New" : data.process_status == 3 ? "Follow Up" : data.process_status == 4 ? "R2V" : data.process_status == 5 ? "Visited" : data.process_status == 6 ? "Closed" : data.process_status == 7 ? "Payment Done" : data.process_status == 8 ? "Not Interested" : ""} </td> <td> <Link to={'/view-lead-details/' + data.id}>View </Link> </td></tr>
                                                    );
                                                })
                                                :
                                                clientData.filter((item) => item.next_follow_date <= date).map((data) => {
                                                    index();
                                                    return (
                                                        <tr><td> {i}</td> <td>{data.next_follow_date} / {data.next_follow_time}</td> <td>{data.name}</td> <td> {data.phone} </td> <td>{data.site_name}</td> <td className='crm-color-primary'> {data.process_status == 1 ? "New" : data.process_status == 2 ? "New" : data.process_status == 3 ? "Follow Up" : data.process_status == 4 ? "R2V" : data.process_status == 5 ? "Visited" : data.process_status == 6 ? "Closed" : data.process_status == 7 ? "Payment Done" : data.process_status == 8 ? "Not Interested" : ""} </td> <td> <Link to={'/view-lead-details/' + data.id}>View </Link> </td></tr>
                                                    );
                                                })
                                            }
                                        </>
                                        : <><tr><td>Empty</td></tr></>
                                }
                            </table>
                        </div>

                        <div className='crm-phone-table hide-visible'>
                            <p style={{ "display": "none" }}>{i = 0}</p>
                            {
                                clientData.length != 0 ?
                                    <>
                                        {filterStatus != "" ?
                                            clientData.filter((item) => item.next_follow_date == filterStatus).map((data) => {
                                                index();
                                                return (
                                                    <>
                                                        <div className='phone-table-item'>
                                                            <div className='table-item-left'>
                                                                <h2> {i} </h2>
                                                            </div>
                                                            <div className='table-item-right'>
                                                                <div>
                                                                    <h2>{data.name}</h2>
                                                                </div>
                                                                <div>
                                                                    <h2>+91 {data.phone} </h2>
                                                                </div>
                                                                <div>
                                                                    <p> {data.email} </p>
                                                                </div>
                                                                <div></div>
                                                                <div>
                                                                    <p> Follow Date: {data.next_follow_date} / {data.next_follow_time} </p>
                                                                </div>
                                                                <div>
                                                                    <p className='crm-color-primary'>
                                                                        {data.process_status == 1 ? "New" : data.process_status == 2 ? "New" : data.process_status == 3 ? "Follow Up" : data.process_status == 4 ? "R2V" : data.process_status == 5 ? "Visited" : data.process_status == 6 ? "Closed" : data.process_status == 7 ? "Payment Done" : data.process_status == 8 ? "Not Interested" : ""}
                                                                    </p>
                                                                    <Link to={'/view-lead-details/' + data.id}>View </Link>
                                                                </div>

                                                                <div className='lead-for'>
                                                                    <span>Lead for: {data.site_name}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })
                                            : clientData.filter((item) => item.next_follow_date <= date).map((data) => {
                                                index();
                                                return (
                                                    <>
                                                        <div className='phone-table-item'>
                                                            <div className='table-item-left'>
                                                                <h2> {i} </h2>
                                                            </div>
                                                            <div className='table-item-right'>
                                                                <div>
                                                                    <h2>{data.name}</h2>
                                                                </div>
                                                                <div>
                                                                    <h2>+91 {data.phone} </h2>

                                                                </div>
                                                                <div>
                                                                    <p> {data.email} </p>
                                                                </div>
                                                                <div>
                                                                    <p> Follow Date: {data.next_follow_date} / {data.next_follow_time} </p>
                                                                </div>
                                                                <div>
                                                                    <p className='crm-color-primary'>
                                                                        {data.process_status == 1 ? "New" : data.process_status == 2 ? "New" : data.process_status == 3 ? "Follow Up" : data.process_status == 4 ? "R2V" : data.process_status == 5 ? "Visited" : data.process_status == 6 ? "Closed" : data.process_status == 7 ? "Payment Done" : data.process_status == 8 ? "Not Interested" : ""}
                                                                    </p>
                                                                    <Link to={'/view-lead-details/' + data.id}>View </Link>
                                                                </div>

                                                                <div className='lead-for'>
                                                                    <span>Lead for: {data.site_name}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })
                                        }
                                    </>
                                    : <><tr><td>Empty</td></tr></>
                            }
                        </div>

                    </>
                }
            </div>
        </>
    )
}

export default CallerFollowUps;